import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { CrudService, PageConsult, PageConsultInput } from "./Services";
import { baseURL } from "@/api/rest";
import { clone, filterKeys } from "@/utils/object";

let instance: UnidadeMedidaServe;

export interface UnidadeMedida {
  id: number;
  sigla: string;
  createdAt?: Date;
  updatedAt?: Date;
  descricao: string;
}

export const initialUnidadeMedida: Readonly<UnidadeMedida> = Object.freeze({
  id: null!,
  sigla: null!,
  createdAt: null!,
  updatedAt: null!,
  descricao: null!,
});

export function getInstance(): UnidadeMedidaServe {
  if (!instance) {
    instance = new UnidadeMedidaServe();
  }
  return instance;
}

export default class UnidadeMedidaServe implements CrudService<UnidadeMedida> {
  async consultar(
    variables: PageConsultInput,
  ): Promise<PageConsult<UnidadeMedida>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            unidadeMedidas(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                sigla
                descricao
                updatedAt
                createdAt
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { unidadeMedidas } } = result;
    return unidadeMedidas;
  }

  private prepareData(input: UnidadeMedida) {
    const data = filterKeys(clone(input), [
      "sigla",
      "descricao",
    ]);
    return data;
  }

  async cadastrar(input: UnidadeMedida): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          input: this.prepareData(input),
        },
        mutation: gql`
          mutation($input: UnidadeMedidaInput!) {
            createUnidadeMedida(input: $input)
          }
        `,
      });
    const { data: { createUnidadeMedida } } = result;
    return createUnidadeMedida;
  }

  async atualizar(id: number, data: UnidadeMedida) {
    await client
      .mutate({
        variables: {
          id,
          input: this.prepareData(data),
        },
        mutation: gql`
          mutation($id: ID!, $input: UnidadeMedidaInput!) {
            updateUnidadeMedida(id: $id input: $input)
          }
        `,
      });
  }

  async excluir(id: number) {
    await client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation($id: ID!) {
            deleteUnidadeMedida(id: $id)
          }
        `,
      });
  }

  async getById(id: number): Promise<UnidadeMedida> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            unidadeMedida(id: $id) {
              id
              createdAt
              updatedAt
              sigla
              descricao
            }
          }
        `,
      });
    const { data: { unidadeMedida } } = result;
    return unidadeMedida;
  }
}
