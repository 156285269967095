<template>
  <p-dialog
    :header="`${id ? 'Editar' : 'Cadastrar'} Unidade de Medida`"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <form class="form" @submit.prevent="salvar">
      <div class="p-fluid">
        <div class="p-field">
          <label>Sigla:</label>
          <p-inputtext v-model="data.sigla" required />
        </div>
        <div class="p-field">
          <label>Descrição:</label>
          <p-inputtext v-model="data.descricao" required />
        </div>
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Salvar"
          :icon="`pi pi-save${saving || loading ? ' p-spin' : ''}`"
          :disabled="saving || loading"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import {
  getInstance,
  UnidadeMedida,
  initialUnidadeMedida,
} from "@/services/UnidadeMedidaService";
import { defineComponent, PropType, watch } from "vue";
import { useCadastro } from "@/composables/crud";

const status = ["ativa", "inativa", "suspensa"];

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      data,
      reset,
      salvar,
      loading,
      saving,
      load,
    } = useCadastro<UnidadeMedida>(getInstance(), initialUnidadeMedida);

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    return {
      status,
      saving,
      loading,
      data,
      reset,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>